.exam-question-top-container{ 
    min-height: calc(100vh - 65px);
    background-color: #F5F8F0;
}
 
 

.button-2{
    margin-right: 50px;
    width: 190px;
    height: 50px;
    background: rgba(167, 205, 217, 1);
    font-family: "Readex Pro";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color:rgba(255, 255, 255, 1);
    border:0;
}

 

.exam-question-type-container{
    background: rgba(255, 255, 255, 1);
}

.exam-question-type-container p{
    padding: .5rem 1rem;font-size: 18px;font-weight: 500;color: #545454;
}

.exam-question-paragraph{  
    font-weight: 600;  
    color: rgba(47, 48, 68, 1);
}

.exam-question-outline-para{ 
    font-size: 14.8px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.exam-question-ul-list li{
    list-style-type: disc;
    margin-left: 22px;
}

.exam__btns__box .btn { padding: 12px 24px;min-width: 140px; }
.exam__btns__box .btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ABBBC1;
    --bs-btn-border-color: #ABBBC1;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #99b5bf;
    --bs-btn-hover-border-color: #99b5bf;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #99b5bf;
    --bs-btn-active-border-color: #99b5bf;
    --bs-btn-disabled-bg: #b8c0c7;
    --bs-btn-disabled-border-color: #b8c0c7;
}
 
 
 

.exam-question-timer-container{
    background: rgba(62, 190, 66, 1); 
    border-radius: 10px;
}


.exam-question-profile-container{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(217, 217, 217, 1);
    padding: 20px;
}

.exam-question-timer{
    padding: 10px;
}

.exam-question-timer h1{ 
    font-size: 28px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    color: #FFFFFF;
}

/* .exam-question-timer p{
    font-family: "Readex Pro";
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    color: rgba(255, 255, 255, 0.84);
} */

.exam-question-attempted-details{
    background: rgba(255, 255, 255, 1);
}

.exam-question-attempted-details h1{ 
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    padding: 10px;
}


.exam-question-attempted-details p{ 
    font-size: 18px;
    font-weight: 400; 
    color: rgba(84, 84, 84, 1); 
}

.exam-question-attempted-question-details-container{
    display: flex;gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.exam-question-attempted-question-details-container .cont-1{
    width: 40px;
    height: 40px;color: var(--white);
    border-radius: 5px;
    background: rgba(62, 190, 66, 1);
}

.exam-question-attempted-question-details-container .cont-2{
    width: 40px;color: var(--white);
    height: 40px;
    border-radius: 5px;
    background: rgba(255, 73, 85, 1);
}

.exam-question-attempted-question-details-container .cont-3{
    width: 40px;color: var(--white);
    height: 40px;
    border-radius: 5px;
    background: rgba(0, 164, 216, 1);
}

.exam-question-attempted-question-details-container .cont-4{
    width: 40px;color: var(--white);
    height: 40px;
    border-radius: 5px;
    background: rgba(190, 188, 188, 1);
}

.cont-1 p{
    text-align: center;
    background: rgba(190, 188, 188, 1);
}

.cont-2 p{
    text-align: center;
    background: rgba(190, 188, 188, 1);
}

.cont-3 p{
    text-align: center;
    background: rgba(190, 188, 188, 1);
}

.cont-4 p{
    text-align: center;
    background: rgba(190, 188, 188, 1);
}

.exam-question-bottom-1-container{
    background: rgba(255, 255, 255, 1);
    margin-bottom: 50px;
}

.exam-question-bottom-1-container h1{
    font-family: "Readex Pro";
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    padding: 12px;
}

.exam-question-result-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* margin-top: 180px; */
}

.result-1{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: rgba(62, 190, 66, 1);
}

.result-2{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: rgba(255, 73, 85, 1);
}

.result-3{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: rgba(0, 164, 216, 1);
}

.result-4{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: rgba(190, 188, 188, 1);
}
 

.exam-question-button-one-container .button-one{
    background: rgba(173, 230, 248, 1); 
    height: 51px;width: 100%;
    border-radius: 5px; 
    color: rgba(25, 108, 135, 1);
    border: 0;
}
.exam-question-button-one-container .button-one:hover,.exam-question-button-one-container .button-one:focus,.exam-question-button-one-container .button-one:active {
    color: rgb(35, 69, 81);
    background: rgb(133, 223, 250); 
}

.exam-question-button-one-container .button-two{
    background: rgba(255, 73, 85, 1); 
    height: 51px;width: 100%;
    border-radius: 5px; 
    color: #FFFFFF;
    border: 0;
}
.exam-question-button-one-container .button-two:hover,.exam-question-button-two-container .button-two:focus,.exam-question-button-two-container .button-two:active {
    color: var(--white);
    background: rgb(249, 15, 31); 
}

.legend__title { margin-top: calc(31vh - 4rem); }

@media (min-width:1599px) {
    .exam-question-top-container .col-xl-4 {
        width: 450px;flex: 0 0 450px;
    }
    .exam-question-top-container .col-xl-8 {
        width: calc(100% - 450px);flex: 0 0 calc(100% - 450px);
    }
}